import Vue from 'vue'

// axios
import axios from 'axios'

localStorage.getItem('endpoint')
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://staffapi.ufobet.net/api',
  // baseURL: localStorage.getItem('endpoint'),
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
